import React from 'react';
import TeamItem from '../../../components/Homepage/Team2/TeamItem2';
import { Col, Container, Image, Row } from 'react-bootstrap';
import { ReactComponent as BgImage } from "../../../assets/images/oval-seccao-team.svg";
import img from "../../../assets/images/homepage/section-3/1-tracejado.svg";
import img2 from "../../../assets/images/jobs/section-3/3-tracejado.svg";
import Spinner from '../../UI/Spinner/Spinner';
import { Suspense } from 'react';
const Carousel = React.lazy(() => import('../../UI/Carousel/Carousel'));


const Team = props => {

    const rita_segurado1 = require('../../../assets/images/team/Rita_Segurado_1.png');
    const rita_segurado1Webp = require('../../../assets/images/team/Rita_Segurado_1.webp');
    const rita_segurado2 = require('../../../assets/images/team/Rita_Segurado_2.png');
    const rita_segurado2Webp = require('../../../assets/images/team/Rita_Segurado_2.webp');

    const erica1 = require('../../../assets/images/team/Erica_1.png');
    const erica1Webp = require('../../../assets/images/team/Erica_1.webp');
    const erica2 = require('../../../assets/images/team/Erica_2.png');
    const erica2Webp = require('../../../assets/images/team/Erica_2.webp');

    const patricia1 = require('../../../assets/images/team/Patricia_1.png');
    const patricia1Webp = require('../../../assets/images/team/Patricia_1.webp');
    const patricia2 = require('../../../assets/images/team/Patricia_2.png');
    const patricia2Webp = require('../../../assets/images/team/Patricia_2.webp');

    const catia1 = require('../../../assets/images/team/Catia_1.png');
    const catia1Webp = require('../../../assets/images/team/Catia_1.webp');
    const catia2 = require('../../../assets/images/team/Catia_2.png');
    const catia2Webp = require('../../../assets/images/team/Catia_2.webp');

    const carolyne1 = require('../../../assets/images/team/Carolyne_1.PNG');
    const carolyne1Webp = require('../../../assets/images/team/Carolyne_1.webp');
    const carolyne2 = require('../../../assets/images/team/Carolyne_2.PNG');
    const carolyne2Webp = require('../../../assets/images/team/Carolyne_2.webp');

    const andre1 = require('../../../assets/images/team/Andre_1.png');
    const andre1Webp = require('../../../assets/images/team/Andre_1.webp');
    const andre2 = require('../../../assets/images/team/Andre_2.png');
    const andre2Webp = require('../../../assets/images/team/Andre_2.webp');

    const rui1 = require('../../../assets/images/team/Rui_1.png');
    const rui1Webp = require('../../../assets/images/team/Rui_1.webp');
    const rui2 = require('../../../assets/images/team/Rui_2.png');
    const rui2Webp = require('../../../assets/images/team/Rui_2.webp');

    const ana1 = require('../../../assets/images/team/Ana_1.png');
    const ana1Webp = require('../../../assets/images/team/Ana_1.webp');
    const ana2 = require('../../../assets/images/team/Ana_2.png');
    const ana2Webp = require('../../../assets/images/team/Ana_2.webp');

    const joao_madaleno1 = require('../../../assets/images/team/Joao_Madaleno_1.png');
    const joao_madaleno1Webp = require('../../../assets/images/team/Joao_Madaleno_1.webp');
    const joao_madaleno2 = require('../../../assets/images/team/Joao_Madaleno_2.png');
    const joao_madaleno2Webp = require('../../../assets/images/team/Joao_Madaleno_2.webp');

    function changeTeam(e) {
        const team = document.getElementById("team-members")
        const sales = document.getElementById("sales-group")
        const recruitment = document.getElementById("recruitment-group")
        if (e.target.id === "sales") {
            team.classList.add("sales")
            team.classList.remove("recruitment")
            sales.classList.add("d-block")
            sales.classList.remove("d-none")
            recruitment.classList.add("d-none")
            recruitment.classList.remove("d-block")
        } else {
            team.classList.remove("sales")
            team.classList.add("recruitment")
            sales.classList.remove("d-block")
            sales.classList.add("d-none")
            recruitment.classList.remove("d-none")
            recruitment.classList.add("d-block")
        }

    }

    return (
        <section id="team" className="my Team">
            <Image src={img} className="img-dashed-t  d-none d-lg-block" />
            <h2 className="h4 text-center fw-600 black mb-0">
                {props.t("homepage:section-4.title")}
            </h2>

            <Container id='team-members' className='team-members'>
                {/* tabs text */}
                <Row className='tab-text-group'>
                    <Col xs={4} className="p-0 p-md-3 d-flex justify-content-center align-items-center">
                        <p className='tab-text fw-600' id='sales' onClick={e => { changeTeam(e) }}>{props.t("homepage:section-4.business")}</p>
                    </Col>
                    <Col xs={{ span: 4, offset: 4 }} className="p-0 p-md-3 d-flex justify-content-center align-items-center">
                        <p className='tab-text fw-600' id='recruitment' onClick={e => { changeTeam(e) }}>{props.t("homepage:section-4.recruitment")}</p>
                    </Col>
                </Row>
                {/* sales */}
                <Container id='sales-group' className='sales-group'>
                    {/* carousel sales */}
                    <div className='d-lg-none'>
                        <Suspense fallback={<Spinner />}>
                            <Carousel className="teamSlide">
                                <TeamItem
                                    quote1={props.t('homepage:section-4.quote-rui-1')}
                                    quote2={props.t('homepage:section-4.quote-rui-2')}
                                    email="rui.viegas@sysmatch.pt"
                                    name="Rui Viegas"
                                    position="Business Director"
                                    src1={rui1}
                                    src1Webp={rui1Webp}
                                    src2={rui2}
                                    src2Webp={rui2Webp}
                                    alt={props.t('homepage:section-4.rui_alt')}
                                />
                                <TeamItem
                                    quote1={props.t('homepage:section-4.quote-rita-segurado-1')}
                                    quote2={props.t('homepage:section-4.quote-rita-segurado-2')}
                                    email="rita.segurado@sysmatch.pt"
                                    name="Rita Segurado"
                                    position="Head of Sales"
                                    src1={rita_segurado1}
                                    src1Webp={rita_segurado1Webp}
                                    src2={rita_segurado2}
                                    src2Webp={rita_segurado2Webp}
                                    alt={props.t('homepage:section-4.rita-segurado_alt')}
                                />
                                <TeamItem
                                    quote1={props.t('homepage:section-4.quote-patricia-correia-1')}
                                    quote2={props.t('homepage:section-4.quote-patricia-correia-2')}
                                    email="patricia.correia@sysmatch.pt"
                                    name="Patrícia Correia"
                                    position="Business Unit Manager"
                                    src1={patricia1}
                                    src1Webp={patricia1Webp}
                                    src2={patricia2}
                                    src2Webp={patricia2Webp}
                                    alt={props.t('homepage:section-4.patricia-correia_alt')}
                                />
                                <TeamItem
                                    quote1={props.t('homepage:section-4.quote-carolyne-veloso-1')}
                                    quote2={props.t('homepage:section-4.quote-carolyne-veloso-2')}
                                    email="carolyne.veloso@sysmatch.pt"
                                    name="Carolyne Veloso"
                                    position="Business Manager"
                                    src1={carolyne1}
                                    src1Webp={carolyne1Webp}
                                    src2={carolyne2}
                                    src2Webp={carolyne2Webp}
                                    alt={props.t('homepage:section-4.carolyne-veloso-alt')}
                                />
                            </Carousel>
                        </Suspense>
                    </div>
                    <Row lg={3} className="px-4 d-none d-lg-flex">
                        <TeamItem
                            quote1={props.t('homepage:section-4.quote-rui-1')}
                            quote2={props.t('homepage:section-4.quote-rui-2')}
                            email="rui.viegas@sysmatch.pt"
                            name="Rui Viegas"
                            position="Business Director"
                            src1={rui1}
                            src1Webp={rui1Webp}
                            src2={rui2}
                            src2Webp={rui2Webp}
                            alt={props.t('homepage:section-4.rui_alt')}
                        />
                        <TeamItem
                            quote1={props.t('homepage:section-4.quote-rita-segurado-1')}
                            quote2={props.t('homepage:section-4.quote-rita-segurado-2')}
                            email="rita.segurado@sysmatch.pt"
                            name="Rita Segurado"
                            position="Head of Sales"
                            src1={rita_segurado1}
                            src1Webp={rita_segurado1Webp}
                            src2={rita_segurado2}
                            src2Webp={rita_segurado2Webp}
                            alt={props.t('homepage:section-4.rita-segurado_alt')}
                        />
                        <TeamItem
                            quote1={props.t('homepage:section-4.quote-patricia-correia-1')}
                            quote2={props.t('homepage:section-4.quote-patricia-correia-2')}
                            email="patricia.correia@sysmatch.pt"
                            name="Patrícia Correia"
                            position="Business Unit Manager"
                            src1={patricia1}
                            src1Webp={patricia1Webp}
                            src2={patricia2}
                            src2Webp={patricia2Webp}
                            alt={props.t('homepage:section-4.patricia-correia_alt')}
                        />
                        <TeamItem
                            quote1={props.t('homepage:section-4.quote-carolyne-veloso-1')}
                            quote2={props.t('homepage:section-4.quote-carolyne-veloso-2')}
                            email="carolyne.veloso@sysmatch.pt"
                            name="Carolyne Veloso"
                            position="Business Manager"
                            src1={carolyne1}
                            src1Webp={carolyne1Webp}
                            src2={carolyne2}
                            src2Webp={carolyne2Webp}
                            alt={props.t('homepage:section-4.carolyne-veloso-alt')}
                        />
                    </Row>
                </Container>
                {/* recruitment */}
                <Container id='recruitment-group' className='recruitment-group d-none'>
                    {/* carousel recruitment */}
                    <div className='d-lg-none'>
                        <Suspense fallback={<Spinner />}>
                            <Carousel className="teamSlide">
                                <TeamItem
                                    quote1={props.t('homepage:section-4.quote-andre-1')}
                                    quote2={props.t('homepage:section-4.quote-andre-2')}
                                    email="andre.potes@sysmatch.pt"
                                    name="André Potes"
                                    position="Recruitment Unit Manager"
                                    src1={andre1}
                                    src1Webp={andre1Webp}
                                    src2={andre2}
                                    src2Webp={andre2Webp}
                                    alt={props.t('homepage:section-4.andre_alt')}
                                />
                                <TeamItem
                                    quote1={props.t('homepage:section-4.quote-erica-figueiredo-1')}
                                    quote2={props.t('homepage:section-4.quote-erica-figueiredo-2')}
                                    email="erica.figueiredo@sysmatch.pt"
                                    name="Erica Figueiredo"
                                    position="Recruitment Team Leader"
                                    src1={erica1}
                                    src1Webp={erica1Webp}
                                    src2={erica2}
                                    src2Webp={erica2Webp}
                                    alt={props.t('homepage:section-4.erica-figueiredo_alt')}
                                />
                                <TeamItem
                                    quote1={props.t('homepage:section-4.quote-catia-coelho-1')}
                                    quote2={props.t('homepage:section-4.quote-catia-coelho-2')}
                                    email="catia.coelho@sysmatch.pt"
                                    name="Cátia Coelho"
                                    position="IT Recruiter"
                                    src1={catia1}
                                    src1Webp={catia1Webp}
                                    src2={catia2}
                                    src2Webp={catia2Webp}
                                    alt={props.t('homepage:section-4.catia-coelho_alt')}
                                />
                                <TeamItem
                                    quote1={props.t('homepage:section-4.quote-joao-madaleno-1')}
                                    quote2={props.t('homepage:section-4.quote-joao-madaleno-2')}
                                    email="joao.madaleno@sysmatch.pt"
                                    name="João Madaleno"
                                    position="IT Recruiter"
                                    src1={joao_madaleno1}
                                    src1Webp={joao_madaleno1Webp}
                                    src2={joao_madaleno2}
                                    src2Webp={joao_madaleno2Webp}
                                    alt={props.t('homepage:section-4.joao_madaleno-alt')}
                                />
                            </Carousel>
                        </Suspense>
                    </div>
                    <Row lg={3} className="px-4 d-none d-lg-flex">
                        <TeamItem
                            quote1={props.t('homepage:section-4.quote-andre-1')}
                            quote2={props.t('homepage:section-4.quote-andre-2')}
                            email="andre.potes@sysmatch.pt"
                            name="André Potes"
                            position="Recruitment Unit Manager"
                            src1={andre1}
                            src1Webp={andre1Webp}
                            src2={andre2}
                            src2Webp={andre2Webp}
                            alt={props.t('homepage:section-4.andre_alt')}
                        />
                        <TeamItem
                            quote1={props.t('homepage:section-4.quote-erica-figueiredo-1')}
                            quote2={props.t('homepage:section-4.quote-erica-figueiredo-2')}
                            email="erica.figueiredo@sysmatch.pt"
                            name="Erica Figueiredo"
                            position="Recruitment Team Leader"
                            src1={erica1}
                            src1Webp={erica1Webp}
                            src2={erica2}
                            src2Webp={erica2Webp}
                            alt={props.t('homepage:section-4.erica-figueiredo_alt')}
                        />
                        <TeamItem
                            quote1={props.t('homepage:section-4.quote-catia-coelho-1')}
                            quote2={props.t('homepage:section-4.quote-catia-coelho-2')}
                            email="catia.coelho@sysmatch.pt"
                            name="Cátia Coelho"
                            position="IT Recruiter"
                            src1={catia1}
                            src1Webp={catia1Webp}
                            src2={catia2}
                            src2Webp={catia2Webp}
                            alt={props.t('homepage:section-4.catia-coelho_alt')}
                        />
                        <TeamItem
                            quote1={props.t('homepage:section-4.quote-ana-1')}
                            quote2={props.t('homepage:section-4.quote-ana-2')}
                            email="ana.fernandes@sysmatch.pt"
                            name="Ana Fernandes"
                            position="IT Recruiter"
                            src1={ana1}
                            src1Webp={ana1Webp}
                            src2={ana2}
                            src2Webp={ana2Webp}
                            alt={props.t('homepage:section-4.ana-alt')}
                        />
                        <TeamItem
                            quote1={props.t('homepage:section-4.quote-joao-madaleno-1')}
                            quote2={props.t('homepage:section-4.quote-joao-madaleno-2')}
                            email="joao.madaleno@sysmatch.pt"
                            name="João Madaleno"
                            position="IT Recruiter"
                            src1={joao_madaleno1}
                            src1Webp={joao_madaleno1Webp}
                            src2={joao_madaleno2}
                            src2Webp={joao_madaleno2Webp}
                            alt={props.t('homepage:section-4.joao_madaleno-alt')}
                        />
                    </Row>
                </Container>
            </Container>

            <Image src={img2} className="img-dashed-b d-none d-lg-block" />
        </section>
    );
}

export default Team;
